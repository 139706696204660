<template>
  <!--   <portfolio> </portfolio>
 -->

  <router-view />
</template>

<script>
/* import Portfolio from "./Portfolio.vue";
 */
export default {
  name: "App",
  components: {
    /*     Portfolio,
     */
  },
};
</script>
