import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)




const routes = [{
        path: '/',
        component: () =>
            import ('@/Portfolio'),
        /* children: [
            // Dashboard
            {
                name: 'dashboard',
                path: '',
                component: () =>
                    import ('@/views/dashboard/Dashboard'),
                meta: {
                    requiresAuth: false
                }
            },


            {
                name: 'mappa',
                path: 'mappa',
                component: () =>
                    import ('@/views/dashboard/Mappa'),
                meta: {
                    requiresAuth: false
                }
            },

            {
                name: 'navette',
                path: 'navette',
                component: () =>
                    import ('@/views/dashboard/navette/Navette'),
                meta: {
                    requiresAuth: false
                }
            },

            {
                name: 'parcheggi',
                path: 'parcheggi',
                component: () =>
                    import ('@/views/dashboard/parcheggi/Parcheggi'),
                meta: {
                    requiresAuth: false
                }
            },

            {
                name: 'portfolio',
                path: 'portfolio',
                component: () =>
                    import ('@/Portfolio'),
                meta: {
                    requiresAuth: false
                }
            },


        ], */
        meta: {
            requiresAuth: false
        }
    },
    {
        name: 'PrivacyPolicy',
        path: '/privacy-policy-for-applications',
        component: () =>
            import ('@/PrivacyPolicy.vue'),
        meta: {
            requiresAuth: false
        }
    }
    /*     {
            name: 'login',
            path: '/login',
            component: () =>
                import ('@/views/Login.vue'),
            meta: {
                requiresGuest: true
            }
        } */

]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: 'active',
    routes
});


/* //router Guards
router.beforeEach((to, from, next) => {

    console.log("before each router.beforeEach");

    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    //next();
    if (requiresAuth && !currentUser) next('/login');
    else if (!requiresAuth && currentUser) next('/');
    else {
        //console.log(`${from.path} to ${to.path}`);
        //console.log("router _ role:" + store.state.role);
        next();
    }
}


); */



export default router